exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contact-us-3-step-tsx": () => import("./../../../src/pages/contact-us-3step.tsx" /* webpackChunkName: "component---src-pages-contact-us-3-step-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-profile-sidebar-tsx": () => import("./../../../src/pages/profile-sidebar.tsx" /* webpackChunkName: "component---src-pages-profile-sidebar-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-confirmation-tsx": () => import("./../../../src/pages/register-confirmation.tsx" /* webpackChunkName: "component---src-pages-register-confirmation-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-saved-contents-tsx": () => import("./../../../src/pages/saved-contents.tsx" /* webpackChunkName: "component---src-pages-saved-contents-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-store-locator-tsx": () => import("./../../../src/pages/store-locator.tsx" /* webpackChunkName: "component---src-pages-store-locator-tsx" */),
  "component---src-pages-studio-assistant-addresses-tsx": () => import("./../../../src/pages/studio-assistant/addresses.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-addresses-tsx" */),
  "component---src-pages-studio-assistant-admin-tsx": () => import("./../../../src/pages/studio-assistant/admin.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-admin-tsx" */),
  "component---src-pages-studio-assistant-change-password-tsx": () => import("./../../../src/pages/studio-assistant/change-password.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-change-password-tsx" */),
  "component---src-pages-studio-assistant-my-events-tsx": () => import("./../../../src/pages/studio-assistant/my-events.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-my-events-tsx" */),
  "component---src-pages-studio-assistant-my-mixes-tsx": () => import("./../../../src/pages/studio-assistant/my-mixes.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-my-mixes-tsx" */),
  "component---src-pages-studio-assistant-my-palettes-tsx": () => import("./../../../src/pages/studio-assistant/my-palettes.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-my-palettes-tsx" */),
  "component---src-pages-studio-assistant-profile-tsx": () => import("./../../../src/pages/studio-assistant/profile.tsx" /* webpackChunkName: "component---src-pages-studio-assistant-profile-tsx" */),
  "component---src-templates-brand-page-index-tsx": () => import("./../../../src/templates/BrandPage/index.tsx" /* webpackChunkName: "component---src-templates-brand-page-index-tsx" */),
  "component---src-templates-events-event-tsx": () => import("./../../../src/templates/Events/Event.tsx" /* webpackChunkName: "component---src-templates-events-event-tsx" */),
  "component---src-templates-events-index-tsx": () => import("./../../../src/templates/Events/index.tsx" /* webpackChunkName: "component---src-templates-events-index-tsx" */),
  "component---src-templates-faq-index-tsx": () => import("./../../../src/templates/Faq/index.tsx" /* webpackChunkName: "component---src-templates-faq-index-tsx" */),
  "component---src-templates-mixer-tsx": () => import("./../../../src/templates/mixer.tsx" /* webpackChunkName: "component---src-templates-mixer-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/News/Article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/News/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-product-content-index-tsx": () => import("./../../../src/templates/ProductContent/index.tsx" /* webpackChunkName: "component---src-templates-product-content-index-tsx" */),
  "component---src-templates-products-product-group-page-tsx": () => import("./../../../src/templates/Products/ProductGroupPage.tsx" /* webpackChunkName: "component---src-templates-products-product-group-page-tsx" */),
  "component---src-templates-products-product-tsx": () => import("./../../../src/templates/Products/Product.tsx" /* webpackChunkName: "component---src-templates-products-product-tsx" */),
  "component---src-templates-products-tech-detail-cards-tsx": () => import("./../../../src/templates/Products/TechDetailCards.tsx" /* webpackChunkName: "component---src-templates-products-tech-detail-cards-tsx" */),
  "component---src-templates-products-tech-detail-table-tsx": () => import("./../../../src/templates/Products/TechDetailTable.tsx" /* webpackChunkName: "component---src-templates-products-tech-detail-table-tsx" */),
  "component---src-templates-resources-by-type-tsx": () => import("./../../../src/templates/Resources/ByType.tsx" /* webpackChunkName: "component---src-templates-resources-by-type-tsx" */),
  "component---src-templates-resources-category-tsx": () => import("./../../../src/templates/Resources/Category.tsx" /* webpackChunkName: "component---src-templates-resources-category-tsx" */),
  "component---src-templates-resources-index-tsx": () => import("./../../../src/templates/Resources/index.tsx" /* webpackChunkName: "component---src-templates-resources-index-tsx" */),
  "component---src-templates-resources-landing-page-tsx": () => import("./../../../src/templates/Resources/LandingPage.tsx" /* webpackChunkName: "component---src-templates-resources-landing-page-tsx" */),
  "component---src-templates-team-index-tsx": () => import("./../../../src/templates/Team/index.tsx" /* webpackChunkName: "component---src-templates-team-index-tsx" */),
  "component---src-templates-team-team-member-detail-page-tsx": () => import("./../../../src/templates/Team/TeamMemberDetailPage.tsx" /* webpackChunkName: "component---src-templates-team-team-member-detail-page-tsx" */),
  "component---src-templates-videos-category-tsx": () => import("./../../../src/templates/Videos/Category.tsx" /* webpackChunkName: "component---src-templates-videos-category-tsx" */),
  "component---src-templates-videos-index-tsx": () => import("./../../../src/templates/Videos/index.tsx" /* webpackChunkName: "component---src-templates-videos-index-tsx" */),
  "component---src-templates-videos-video-tsx": () => import("./../../../src/templates/Videos/Video.tsx" /* webpackChunkName: "component---src-templates-videos-video-tsx" */),
  "component---src-templates-wysiwyg-index-tsx": () => import("./../../../src/templates/Wysiwyg/index.tsx" /* webpackChunkName: "component---src-templates-wysiwyg-index-tsx" */)
}

