import React, { FC, ReactNode } from "react";
import { Toaster } from "react-hot-toast";

type LayoutProps = {
  children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => (
  <>
    {children}
    <Toaster />
  </>
);

export default Layout;
