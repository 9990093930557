import "./src/styles/global.css";
import React from "react";
import type { GatsbyBrowser } from "gatsby";
import Layout from "./src/components/Layout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => <Layout>{element}</Layout>;

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => element;

// fixes a scrolling bug in gatsby v5
// https://github.com/gatsbyjs/gatsby/issues/38201
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // Check if there's a hash in the URL
  if (location.hash) {
    // Scroll to the top of the page

    // Use setTimeout to manage timing and ensure the DOM has updated
    setTimeout(() => {
      // Try to find the element by ID (without the #)
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      console.log(element);
      if (element) {
        const top = window.scrollY + element.getBoundingClientRect().top - 50;
        window.scrollTo({ top, behavior: "smooth" });
      } else {
        // Fallback to the top if the element is not found
        window.scrollTo(0, 0);
      }
    }, 0);
  } else {
    // Handle the normal scroll behavior without a hash
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 0);
  }

  // Prevent Gatsby from handling scroll updates
  return false;
};
